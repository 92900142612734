

.delivery-methods-container{
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.action-layout
  display: flex
  width: 100%
  gap: 12px
  @media screen and (max-width: 960px)
    flex-wrap: wrap
  .store
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%
  .delivery
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%
.payment-type-layout
  display: flex
  width: 100%
  gap: 12px
  @media screen and (max-width: 960px)
    flex-direction: column
    width 100%
  .deposit
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%

  .full-payment
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%

::v-deep .v-select__selection
    max-width: 100%

.text-warning
  font-size: 0.875rem
  color: $color-red

  @media tablet
    font-size: 0.875rem

  @media small
    font-size: 11px

.delivery-item.item {
    align-items: baseline;
}
.item {
    display: flex;
    align-items: center;
    gap: 11px;
    padding: 11px;

    border: 1.2px solid $color-dapp-gray-3;
    cursor: pointer;

    &:first-child&:last-child {
        border-radius: 8px;
    }

    &:first-child:not(:last-child) {
        border-radius: 8px 8px 0 0;
    }

    &:last-child:not(:first-child) {
        border-radius: 0 0 8px 8px;
    }

    &:not(:last-child) {
        border-bottom: none;
    }

    &:last-child:not(:first-child):not(.-is-selected) {
        border-top: none;
    }

    &.-is-selected {
        background-color: $color-fill-radio-button;
        border: 1.2px solid $color-bnn !important;

        .checkmark {
            border: 5.5px solid $color-bnn;
        }
    }

    &.-is-remove-border-top {
        border-top: none;
    }

    &.-is-disabled {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
    }

    .item-label {
        flex: 1;

        > .label {
            font-weight: 500;
            color: rgba(#000000, 0.70);
        }

        > .description-s {

            font-size: 12px;
            font-weight: 600;
            line-height: 1.3;
            color: rgba(#000000, 0.45);
        }
    }

    .item-icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    .checkmark {
        width 16px;
        height 16px;
        border-radius: 50%;
        border: 1px solid #d2d2d7;
        transition: border .1s;
    }

}

.base-radio-button {
	position: relative;
	display: block;
	cursor: pointer;
	user-select: none;

	&.-is-disabled {
		cursor: not-allowed;

		> .label {
			color: $color-absolute-black-45;
		}
	}

	// Hide the browser's default radio
	input {
		display: none;
		opacity: 0;
		width: 0;
		height: 0;
		cursor: pointer;
	}

	// Create a custom radio
	.checkmark {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		height:  16px;
		width: 16px;

		display: flex;
		align-items: center;
		justify-content: center;

		background-color: transparent;
		border-radius: 50%;
		border: 1px solid $color-grey83;
		transition: border 0.1s;
	}

	& input:checked ~ .checkmark {
		border: rem(5.5) solid $color-bnn;
	}

	& input:focus ~ .checkmark {
		box-shadow: 0 0 0 rem(3) $color-functionality-secondary;
	}


	& input:not(:checked):disabled ~ .checkmark {
		border: 2px solid $color-grey-light-50;
	}

	& input:checked:disabled ~ .label,
	& input:not(:checked):disabled ~ .label {
		color: $color-grey-light-50;
	}


	& input:checked:disabled ~ .checkmark {
		border: rem(7) solid $color-grey-light-50;
	}
}

.delivery-item-name {
    font-family: "SF Pro Text";
    font-size: .875rem;
    font-style: normal;
    display: flex;
    align-items: center;
    line-height: 23px

    img {
        width: auto;
        height: 16px;
        margin-right: 8px;
    }
}

.estimated-delivery-times {
    font-weight: 500;
    color: rgba(0, 0, 0, .7);
    font-family: "SF Pro Text";
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pre-order-expected-message {
    margin-top: 1rem;
    font-size: .75rem;
    font-weight: 600;
    line-height: 1.3;
    color: rgba(0, 0, 0, .45);
}

.delivery-item-price {
    font-weight: 600;
    line-height: 1.25012rem;
}

.branch-selection-container
  max-width 100%
  display grid
  justify-content center
  align-items center
  gap 14px
  grid-template-columns: repeat(1, 1fr)
  @media screen and (min-width: 768px)
    grid-template-columns: repeat(2, 1fr)
  .branch
    width 100%
  .time
    width 100%
    grid-column: span 1
    @media screen and (min-width: 768px)
      grid-column: span 2

.time-description
    width: 100%
    text-align: left;
    font-size: 14px;
    color: $color-dark-gray-medium;
    margin-top: 8px;
